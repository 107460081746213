<template>
    <div class="opnePro-wrap">
        <div class="ow-title flex-wc">
            <div class="balck" @click="clickGoBalck">
                <i class="el-icon-arrow-left"></i>
            </div>
            <div class="title">免费开通</div>
        </div>
        <div class="ov-content">
            <div class="ow-c-item">
                <div class="ci-tit flex-wc">
                    <div class="title">套餐选择</div>
                </div>
                <div class="ci-box flex-sbc">
                    <div class="item flex-cc" :class="currentIndex == index?'active':''"
                        v-for="(item,index) in renewList" :key="index"
                        @click="clickRenew(item,index)">
                        {{item.name}}
                    </div>
                </div>
            </div>
            <div class="ovc-item">
                <div class="i-title">账户<span>*</span></div>
                <div class="i-content flex-cc">
                    <el-input
                        placeholder="请输入开通有库云账户手机号码"
                        v-model="info.phone"
                    ></el-input>
                </div>
            </div>
            <div class="ovc-item">
                <div class="i-title">验证码<span>*</span></div>
                <div class="i-box flex-sbc">
                    <el-input v-model="info.code" placeholder="请输入验证码"></el-input>
                    <el-button @click="getVerificationCode" :disabled="countdown > 0"
                        type="primary" plain style="margin-left: 15px">
                        {{ countdown > 0 ? countdown + '秒重新获取' : '获取验证码' }}
                    </el-button>
                </div>
            </div>
        </div>
        <div class="price-wrap flex-sbc">
            <div class="pw-left flex-sbc">
                <div class="item">
                    <div class="tip">订阅年费</div>
                    <div class="price" v-if="activate_package_id == 0">免费</div>
                    <div class="price" v-else>￥{{renewPrice}}/年</div>
                </div>
            </div>
            <div class="btn-wrap flex-cc" @click="getRegister">开通</div>
        </div>
        <!-- 扫码支付 -->
        <el-dialog
            :visible.sync="isPriceCodeShow"
            width="200"
            :show-close="false">
            <div class="price-code flex-coc">
                <img width="140" height="140" class="img" :src="payImg" alt="">
                <div class="tip">微信扫码支付</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import areaObj from '@/utils/area.json';
import api from '@/utils/common.js';
export default {
    data () {
        return {
            info: {
                phone: '',
                code: ''
            },
            countdown: 0,

            renewList: [],
            renewPrice: '',
            currentIndex: 0,
            activate_package_id: '',
            order_number: '',
            payImg: '',
            isPriceCodeShow: false,
            timer: null,
            counter: 0
        }
    },
    mounted () {
        this.getActivatePackage()
    },
    methods: {
        clickGoBalck(){
            this.$router.go(-1) 
        },
        // 获取支付状态
        getPayStatus(){
            let params = {
                order_number: this.order_number
            }
            api.post('/api/login/pay_status',params, (res) => {
                if(res.data.status === 1){
                    this.$message.success('支付成功')
                    this.openState = 1
                    this.isPriceCodeShow = false
                    this.isCodeShow = false
                    clearInterval(this.timer); 
                    setTimeout(() => {
                        this.$router.go(-2) 
                        window.location.href = 'weixin://dl/business/?appid=wx44d4edf1b57daea8';
                    }, 1000);
                }else{
                    this.counter++; 
                    if (this.counter >= 30) { 
                        clearInterval(this.timer); 
                        ElMessage({message: '支付失败',type: 'warning'});
                    }
                }
            });
        },
        // 去支付
        getRegister(){
            if(this.info.phone == ''){
                this.$message.error('请输入开通有库云账号手机号码')
                return
            }
            if(this.info.code == ''){
                this.$message.error('请输入验证码')
                return
            }
            let params = {
                phone: this.info.phone,
                code: this.info.code,
                activate_package_id: this.activate_package_id
            };
            api.post('/api/login/register',params, (res) => {
                if(this.activate_package_id == 0){
                    this.$message.success('开通成功')
                    this.$router.go(-2) 
                    window.location.href = 'weixin://dl/business/?appid=wx44d4edf1b57daea8';
                }else{
                    this.payImg = res.data.qr_code
                    this.order_number = res.data.order_number
                    this.isPriceCodeShow = true
                    this.timer = setInterval(() => {
                        this.getPayStatus();
                    }, 2000);
                }
            });
        },

        // 获取套餐
        getActivatePackage(){
            api.get('/api/login/get_activate_package_whole', {}, (res) => {
                this.renewList = res.data.list;
                this.activate_package_id = this.renewList[0].id;
                this.renewPrice = this.renewList[0].price;
            });
        },
        clickRenew(item,index){
            this.currentIndex = index;
            this.activate_package_id = item.id;
            this.renewPrice = item.price;
        },
        // 获取验证码
        getVerificationCode(){
            this.info.code = ''
            if(this.info.phone == ''){
                this.$message.error('请输入开通有库云账号手机号码')
                return
            }
            if (this.countdown > 0) {
                return; 
            }
            // 发送获取验证码的请求
            api.get('/api/login/get_register_vif', {phone: this.info.phone}, (res) => {
                this.countdown = 60;
                this.$message.success(res.msg)
                const timer = setInterval(() => {
                    this.countdown--;
                    if (this.countdown <= 0) {
                        clearInterval(timer); 
                    }
                }, 1000);
            });
        },
    },
}
</script>

<style lang='scss'>
.opnePro-wrap{
    .ow-title{
        height: 50px;
        padding: 0 20px;
        position: relative;
        .balck{
            width: 50px;
            .el-icon-arrow-left{
                font-size: 18px;
                color: #101010;
                font-weight: bold;
            }
        }
        .title{
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            font-size: 16px;
            color: #101010;
            font-weight: bold;
        }
    }
    .rp-image{
        .image{
            width: 100%;
            height: 160px;
            vertical-align: bottom;
        }
        .title-wrap{
            background: #e5f0ff;
            padding: 6px 20px;
            .tit{
                font-size: 14px;
                color: #101010;
                font-weight: bold;
                padding-right: 15px;
            }
        }
    }
    .ov-content{
        background: #fff;
        padding-bottom: 30px;
        .ow-c-item{
            padding: 20px 20px 0 20px;
            .ci-tit{
                font-size: 18px;
                font-weight: bold;
                color: #101010;
                .tag{
                    color: #ff0000;
                }
            }
            .ci-box{
                padding: 15px 0;
                width: 100%;
                flex-wrap: wrap;
                .item{
                    background: #eff5ff;
                    border-radius: 6px;
                    font-size: 14px;
                    color: #000;
                    cursor: pointer;
                    height: 70px;
                    width: calc((100% - 30px)/2);
                    margin-bottom: 20px;
                }
                .active{
                    background: #0068ff;
                    color: #fff;
                }
            }
        }
        .ovc-item{
            padding: 15px 20px 0px 20px;
            .i-title{
                font-size: 16px;
                color: #101010;
                padding-bottom: 20px;
                font-weight: bold;
                span{
                    color: #ff0000;
                }
            }
            .i-content{
                .titel{
                    padding-left: 10px;
                    padding-right: 10px;
                }  
                .titel:first-child{
                    padding-left: 0;
                }
                .el-input__inner{
                    height: 46px;
                }
            }
            .i-box{
                .el-input__inner{
                    height: 46px;
                }
                .el-button{
                    height: 46px;
                }
            }
        }
    }
    .price-wrap{
        background: #f5f5f5;
        padding: 20px 20px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        .pw-left{
            .item{
                .price{
                    font-size: 20px;
                    color: #101010;
                    font-weight: bold;
                    padding-top: 5px;
                }
                .tag{
                    font-size: 12px;
                    padding-top: 10px;
                }
            }
        }
    }
    .btn-wrap{
        background: #0068ff;
        width: 80px;
        height: 40px;
        border-radius: 5px;
        font-size: 16px;
        color: #fff;
    }
    .price-code{
        padding: 20px;
        .tip{
            font-size: 14px;
            padding-top: 10px;
        }
    }
}
</style>
